import {FormGroup} from '@angular/forms';
import {QuickApplicationConstant} from '../../quick-application/quick-application.constant';
import {FileUpload} from '../../interface/file-upload.interface';
import {NotifierData} from '../component/application-notifier/notifier-data.interface';
import {ApplicationNotifierConstant} from '../constant/notifier.constant';
import { APP_URL } from '../../../environments/environment';
import { ElementRef } from '@angular/core';
import { ApplicationConstant } from '../constant/app.constant';

/**
 * Utility class for the Application.
 */
export class AppUtil {

  /**
   * Check for file size less than 5MB.
   *
   * @param file to upload
   * @param maxFileSize file size
   * @param fileConfig - file details
   */
  static checkFileSize(file, maxFileSize, fileConfig?: any) {
    if (file) {
      if (fileConfig?.sizeType === ApplicationConstant.sizeTypeKB) {
        const kbFileSize = Math.round(file.size / 1024);
        return kbFileSize < fileConfig?.maxFileSize;
      } else {
        const fileSize = file.size;
        const kbFileSize = Math.round(fileSize / 1024);
        return kbFileSize < (1024 * maxFileSize);
      }
    }
    return false;
  }

  /**
   * Check if the file is a valid extension.
   *
   * @param fileName name of the file.
   * @param validFileExtensions supported file extensions
   */
  static checkFileExtension(fileName, validFileExtensions) {
    let isValidExtension = false;
    for (const extension of validFileExtensions) {
      if (fileName.substr(fileName.length - extension.length,
        extension.length).toLowerCase() === extension.toLowerCase()) {
        isValidExtension = true;
        break;
      }
    }
    return isValidExtension;
  }

  /**
   * Create the file name for the file upload
   *
   * @param uid usrId for the user
   * @param uploadFileText upload file text
   */
  static createFileName(uid: number, uploadFileText: string) {
    return `${uid}_${uploadFileText}_${Date.now()}`;
  }

  /**
   * Create the website url.
   *
   * @param endpoint of the page.
   */
  static createWebsiteURL(endpoint) {
    return `${APP_URL.WEBSITE}${endpoint}`;
  }

  /**
   * To enable or disable the form fields
   *
   * @param form on which fields needs to enable or disable
   * @param fieldArray fields which needs to be enable or disabled.
   * @param isEnabled boolean value true for enable and false for disable.
   */
  static enableOrDisableFormFields(form: FormGroup, fieldArray: Array<any>, isEnabled: boolean) {

    for (const field of fieldArray) {
      if (isEnabled) {
        form.get(field.key).enable();
      } else {
        form.get(field.key).disable();
        // reset the form value on disable
        form.get(field.key).reset();
      }
    }
  }

  /**
   * Create the new date by subtracting the given no. of years
   */
  static getNewDateByReduceYears(date: Date, years: number) {
    date.setFullYear(date.getFullYear() - years);
  }

  /**
   * Update the flag on the form
   *
   * @param config form
   * @param countryCode country iso
   */
  static updateFlag(config, countryCode) {
    if (countryCode !== 'null') {
      config.selectedCountryISO = countryCode;
    }
  }
  /**
   * Calculate year difference
   *
   * @param dobYr Student dob year
   */
   static calculateYear(dobYr: number) {
    if (dobYr !== null) {
      const date = new Date();
      const year = date.getFullYear();
      const yrDiff =   year - dobYr;
      return yrDiff;
    }
  }
  /**
   * Scroll to the first element with error in the form.
   */
  static scrollFirstElementWithError() {
    const target = document.querySelector('.ng-invalid:not(form)');

    if (target) {
      target.scrollIntoView({ behavior: 'smooth' });
    }
  }

  /**
   * Scroll to first element with dynamic form.
   */
  static scrollToDynamicFormElementWithError() {
    let firstInvalidControl: any;
    let all = document.getElementsByClassName('ng-invalid');
    for (var i = 0; i < all.length; i++) {
      if (all[i].tagName == 'INPUT' || all[i].tagName === 'MAT-SELECT' || all[i].tagName === 'TEXTAREA') {
        firstInvalidControl = all[i];
        break;
      }
    }
    if (firstInvalidControl) {
      firstInvalidControl.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  /**
   * Validate the start date and end date.
   *
   * @param group FormGroup
   */
  static validateStartDateAndEndDate(group: FormGroup) {

    if (group.get(QuickApplicationConstant.workDetailsForm.start_date_year.key)
      && group.get(QuickApplicationConstant.workDetailsForm.start_date_year.key).value &&
      group.get(QuickApplicationConstant.workDetailsForm.start_date_month.key)
      && group.get(QuickApplicationConstant.workDetailsForm.start_date_month.key).value &&
      group.get(QuickApplicationConstant.workDetailsForm.end_date_year.key)
      && group.get(QuickApplicationConstant.workDetailsForm.end_date_year.key).value &&
      group.get(QuickApplicationConstant.workDetailsForm.end_date_month.key)
      && group.get(QuickApplicationConstant.workDetailsForm.end_date_month.key).value) {

      const startDate = new Date(Date.parse(`${group.get(QuickApplicationConstant.workDetailsForm.start_date_month.key).value} 1, ${group.get(QuickApplicationConstant.workDetailsForm.start_date_year.key).value}`));
      const endDate = new Date(Date.parse(`${group.get(QuickApplicationConstant.workDetailsForm.end_date_month.key).value} 1, ${group.get(QuickApplicationConstant.workDetailsForm.end_date_year.key).value}`));
      if (startDate.getTime() >= endDate.getTime()) {
        return { invalidExperience: true };
      }
    }
    return null;
  }

  /**
   * Reset the phone number
   *
   * @param form Form
   * @param fieldNameConfig name of the field
   */
  static resetPhoneNumber(form: FormGroup, fieldNameConfig: string) {
    form.controls[fieldNameConfig].valueChanges.subscribe((phoneNumber) => {
        if (form.value[fieldNameConfig] && form.value[fieldNameConfig].countryCode
          && phoneNumber && phoneNumber.countryCode && phoneNumber.countryCode !== form.value[fieldNameConfig].countryCode) {
          form.controls[fieldNameConfig].setValue('');
        }
      }
    );
  }

  /**
   * File upload set the attributes
   *
   * @param fileData data of file upload
   * @param form FormGroup
   * @param fileTextKey text key of the file
   * @param fileNameKey uploaded file
   * @param upload array of file to upload
   * @param notifierData message to be shown.
   */
  static multipleFileUpload(fileData: any, form: FormGroup, fileTextKey: string, fileNameKey: string, upload: Array<FileUpload>,
                            notifierData: NotifierData) {
    const fileUpload = {} as FileUpload;
    fileUpload.url = fileData.url;
    fileUpload.id = fileData.image_id;
    fileUpload.name = form.value[fileTextKey];
    fileUpload.notifier = notifierData;
    upload.push(fileUpload);
    const gradeUploadList = upload.map(({notifier, ...gradeUploads}) => gradeUploads);
    form.controls[fileNameKey].setValue(gradeUploadList);
    form.controls[fileTextKey].setValue('');
  }

  /**
   * File upload set the attributes
   *
   * @param fileData data of file upload
   * @param fileName name of the file
   * @param upload array of file to upload
   */
  static singleFileUpload(fileData: any, fileName: string, upload: FileUpload) {
    upload.url = fileData.url;
    upload.id = fileData.image_id;
    upload.notifier = ApplicationNotifierConstant.file_upload;
    upload.name = fileName;
  }

  /**
   * Group the array according to the key
   *
   * @param items array details
   * @param key on which grouping to be done.
   */
  static groupArrayByGivenKey(items, key) {
    return items.reduce(
      (result, item) => ({
        ...result,
        [item[key]]: [...(result[item[key]] || []), item, ],
      }), {}, );
  }

  /**
   * Get the filtered list.
   *
   * @param list full list of elements.
   * @param selectedList selected ones.
   * @param idKeyForList primary key for the full list.
   * @param idKeyForSelectedList primary key for the selected list.
   */
  static getFilterList(list: Array<any>, selectedList: Array<any>, idKeyForList: string, idKeyForSelectedList: string) {
    return list.filter(({ [idKeyForList]: id1 }) => !selectedList.some(({ [idKeyForSelectedList]: id2 }) => id2 === id1));
  }

  /**
   * Get the path from the URL.
   *
   * @param url get the pathname.
   */
  static getPathFromURL(url: string) {
    if (url) {
      return new URL(url).pathname.slice(1);
    }
    return null;
  }

  /**
   * Get the array of string from the array of list.
   *
   * @param list of object.
   * @param filterKey from the list.
   */
  static getArrayOfStringFromArrayOfObject(list: Array<any>, filterKey: string): Array<string> {
    return list && list.length && filterKey ? list.map(field => field[filterKey]) : [''];
  }

  /**
   * All object value is null or empty or undefined or false then return true.
   *
   * @param field is the object for checking the value.
   */
  static allObjectAttributeIsNull(field: object) {
    return Object.values(field).every(value => (value === null || value === '' || value === undefined || value === false));
  }

  /**
   * Get Permission name for Module
   *
   * @param permissionName name of the permission.
   */
  static getPermissionNameForModule(permissionName: string) {
    return {
      only: permissionName,
      redirectTo: '404'
    }
  }

  /**
   * Get pattern for link validation
   */
  static get getPatternLink() {
    return /^(https):\/\/?(?:www\.)?[a-z0-9-]+(?:\.[a-z0-9-]+)+[^\s]*$/;
  }

  /**
   * Extract file name from the URL
   */
  static extractFileName(url: string) {
    return url.substring(url.lastIndexOf('/') + 1);
  }

  /**
   * Extract file extension from the URL
   */
  static extractExtensionFromURL(url: string) {
    return url.split(/[#?]/)[0].split('.').pop().trim();
  }

  /**
   * Focus into form field which is invalid
   */
  static focusFirstInvalidControlPlus(elementRef: ElementRef): void {
    const firstElementWithErrors: HTMLElement = elementRef.nativeElement.querySelector(`form .ng-invalid`);
    if (firstElementWithErrors) {
      firstElementWithErrors.focus();
    }
  }

  /**
   * Check whether numeric or not
   * @param value
   */
  static isNumeric(value: string) {
    return /^\d+$/.test(value);
  }

}

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticateService } from '../services/authenticate.service';
import { ApplicationConstant } from '../shared/constant/app.constant';
import { RoutingConstant } from '../shared/constant/routing.constant';
import { CommonService } from '../services/common.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authenticateService: AuthenticateService,
              private commonService: CommonService,
              private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (ApplicationConstant.autoLoginUrls.includes(next.routeConfig.path)
      && next.queryParams && Object.keys(next.queryParams).length !== 0 && next.queryParams.uid) {

      const url = state.url.split('?')[0];
      
      // check the url contains cid for bursary and exemption
      if (ApplicationConstant.autoLoginUrlWithCId.includes(next.routeConfig.path)) {

        if (next.queryParams.cid) {
          return this.getTokenAndCheckUserExist(next.queryParams, url, next.routeConfig.path);
        } else {
          this.router.navigateByUrl(ApplicationConstant.appRouting.LOGIN);
        }
      } else {
        return this.getTokenAndCheckUserExist(next.queryParams, url, next.routeConfig.path);
      }
    } else if (this.authenticateService.isTokenAvailable()) {

      // check if the session is a temporary Page Access.
      if (this.authenticateService.isTempLogin()) {
        if (state.url.indexOf(this.authenticateService.getTempAccessPage()) > -1) {
          return true;
        } else {
          return this.router.navigate([ApplicationConstant.appRouting.LOGIN], { queryParams: { returnUrl: state.url } });
        }
      } else if (state.url.includes(RoutingConstant.ADMIN)) {
        return this.authenticateService.checkAdminUserAndRoutePermission(state.url);
      } else {
        return true;
      }
    } else if (state.url.includes(RoutingConstant.QUICK_APPLICATION)) {
      // Allow quick application as an open page
      return true;
    } else {
      return this.router.navigate([ApplicationConstant.appRouting.LOGIN], { queryParams: { returnUrl: state.url } });
    }
  }

  /**
   * Get the authorized user.
   *
   * @param queryParams cid and uid of the user.
   * @param url current url to navigate
   * @param accessPage temporary page to access
   */
  getTokenAndCheckUserExist(queryParams: any, url: string, accessPage: string): Promise<boolean> {

    const userDetails = {};
    userDetails[ApplicationConstant.formMetaFields.isShortCourse] = false;
    if (queryParams.uid) {
      userDetails[ApplicationConstant.formMetaFields.userId] = queryParams.uid;
    }
    if (queryParams.cid) {
      userDetails[ApplicationConstant.formMetaFields.courseId] = queryParams.cid;
    }

    if (queryParams.mail) {
      userDetails[ApplicationConstant.formMetaFields.email] = queryParams.mail;
      return this.authenticateService.validateUserFromEmail(userDetails, url, accessPage);
    } else {
      return this.authenticateService.validateUserSessionInOldLms(userDetails, url);
    }
  }

}
